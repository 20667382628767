<template>
  <v-dialog v-model="dialog" persistent :max-width="650">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="warning"
        style="margin-top: 15px"
        dark
        block
        v-bind="attrs"
        v-on="on"
      >
        Generar Gasto
      </v-btn>
    </template>
    <v-card :loading="loading">
      <v-card-title class="text-h6"> Nuevo Gasto </v-card-title>
      <div class="centered-table">
        <v-progress-circular
          v-if="mostrarLoading"
          indeterminate
          color="primary"
          size="50"
        ></v-progress-circular>
        <div v-else>
          <v-simple-table class="my-table">
            <template v-slot:default>
              <thead>
                <tr>
                  <th></th>
                  <th>Neto</th>
                  <th>IVA</th>

                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(gasto, index) in gastos"
                  :key="index"
                  v-if="gasto.administrativos.administrativo > 0"
                >
                  <td>Honorarios MFSA</td>

                  <td>
                    {{
                      formatCurrency(
                        gasto.administrativos.administrativo.toFixed(2)
                      )
                    }}
                  </td>
                  <td>
                    {{ formatCurrency(gasto.administrativos.iva.toFixed(2)) }}
                  </td>
                  <td>
                    {{
                      formatCurrency(
                        (
                          gasto.administrativos.administrativo +
                          gasto.administrativos.iva
                        ).toFixed(2)
                      )
                    }}
                  </td>
                  <td>
                    <v-btn
                      small
                      fab
                      dark
                      color="red"
                      @click="eliminarGasto(gasto.idGasto)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
                <!-- Gastos de escribanos -->
                <tr
                  v-for="(gasto, index) in gastos"
                  :key="index"
                  v-if="gasto.administrativos.otorgamiento > 0"
                >
                  <td>Gastos de Escribano</td>

                  <td>
                    {{
                      formatCurrency(
                        gasto.administrativos.otorgamiento.toFixed(2)
                      )
                    }}
                  </td>
                  <td>
                    {{ formatCurrency(gasto.administrativos.iva.toFixed(2)) }}
                  </td>
                  <td>
                    {{
                      formatCurrency(
                        (
                          gasto.administrativos.otorgamiento +
                          gasto.administrativos.iva
                        ).toFixed(2)
                      )
                    }}
                  </td>
                  <td>
                    <v-btn
                      small
                      fab
                      dark
                      color="red"
                      @click="eliminarGasto(gasto.idGasto)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>

                <!-- Gastos de IIBB -->
                <tr
                  v-for="(gasto, index) in gastos"
                  :key="index + '_iibb'"
                  v-if="gasto.iibb > 0"
                >
                  <td>IIBB</td>
                  <td>{{ formatCurrency(gasto.iibb.toFixed(2)) }}</td>
                  <td>0.00</td>

                  <td>{{ formatCurrency(gasto.iibb.toFixed(2)) }}</td>
                  <td>
                    <v-btn
                      small
                      fab
                      dark
                      color="red"
                      @click="eliminarGasto(gasto.idGasto)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>

                <!-- Totales -->
                <tr>
                  <td><b>Totales</b></td>
                  <td>
                    <b> {{ formatCurrency(totalNeto.toFixed(2)) }}</b>
                  </td>
                  <td>
                    <b> {{ formatCurrency(totalIva.toFixed(2)) }}</b>
                  </td>

                  <td>
                    <b> {{ formatCurrency(totalGeneral.toFixed(2)) }}</b>
                  </td>
                </tr>
              </tbody>
            </template>
            <!-- Cerrar aquí -->
          </v-simple-table>
        </div>
      </div>
      <v-card-text>
        <v-row>
          <v-col :lg="iva_flag ? 6 : 12">
            <v-text-field
              label="Monto"
              @keyup="calcularIVA"
              v-model="montoFormatted"
              @input="handleMonto"
              @blur="formatMonto"
            >
            </v-text-field>
          </v-col>
          <v-col lg="6" v-if="iva_flag">
            <v-text-field
              type="number"
              v-model="iva"
              prepend-inner-icon="mdi-currency-usd"
              label="IVA"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-col>
          <v-btn-toggle v-model="tipo_gasto" borderless color="primary">
            <v-btn value="otorgamiento">
              <span class="hidden-sm-and-down">Otorgamiento</span>
            </v-btn>
            <v-btn value="posteriores" disabled>
              <span class="hidden-sm-and-down">Posteriores</span>
            </v-btn>
            <v-btn value="iibb">
              <span class="hidden-sm-and-down">IIBB</span>
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col>
          <v-btn-toggle
            v-if="tipo_gasto !== 'iibb'"
            v-model="iva_flag"
            borderless
            color="green"
          >
            <v-btn :value="false">
              <span class="hidden-sm-and-down">s/IVA</span>
            </v-btn>
            <v-btn :value="true" @click="calcularIVA">
              <span class="hidden-sm-and-down">c/IVA</span>
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="green darken-1"
          text
          :disabled="monto <= 0 || tipo_gasto === ''"
          @click="guardarGasto"
        >
          Cargar
        </v-btn>
        <v-btn color="red darken-1" text @click="cerrarModal"> Cerrar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { API_URL } from '@/common/config';
import axios from 'axios';

export default {
  name: 'ModalGastos',
  props: ['lineacreditocliente_id', 'idDesembolso', 'anr'],
  data: () => ({
    tipo_gasto: '',
    monto: 0,
    iva: 0,

    total: 0,
    totalGastos: 0,
    honorariosMfs: 0,
    honorariosMfsIva: 0,
    iibbHonorariosMfs: 0,
    totalHonorariosMfs: 0,

    otrosGastos: 0,
    totalOtrosGastos: 0,
    ivaOtrosGastos: 0,
    iibbOtrosGastos: 0,

    totalNeto: 0,
    totalIva: 0,
    totalIibb: 0,
    totalGeneral: 0,

    loading: false,
    iva_flag: false,
    dialog: false,
    gastos: [],
    mostrarLoading: false,
    header: {
      headers: {
        token: localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
    },
    formatter: new Intl.NumberFormat('es-AR', {
      style: 'currency',
      currency: 'ARS',
    }),
  }),
  computed: {
    montoFormatted: {
      get() {
        return this.formatCurrency(this.monto);
      },
      set(value) {
        const valueAsString = String(value || '');
        const cleanValue = valueAsString.replace(/[$,.]/g, ''); // Eliminar $ y .
        const numericValue = parseFloat(cleanValue) / 100; // Dividir por 100

        // Validar que el valor sea un número válido
        if (isNaN(numericValue)) {
          this.monto = 0; // Establecer como 0 si no es válido
        } else {
          this.monto = numericValue; // Guardar el valor numérico
        }
      },
    },
  },
  methods: {
    async eliminarGasto(idGasto) {
      if (!idGasto) {
        return;
      }
      this.$swal({
        title: '¿Esta seguro?',
        text: 'Esta acción elimina el gasto',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No, Cancelar',
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.mostrarLoading = true;
          const url = `${API_URL}api/v1/gastos/${idGasto}`;
          const header = {
            headers: {
              token: this.token,
            },
          };

          axios
            .delete(url, header)
            .then(async (response) => {
              if (response.data.status === 'success') {
                await this.$swal.fire({
                  icon: 'info',
                  title: 'Desembolso Eliminado',
                  showConfirmButton: false,
                  timer: 1500,
                });

                this.getGastos();
              }
              this.mostrarLoading = false;
            })

            .catch((error) => {
              console.log(error);
              this.mostrarLoading = false;
            });
        }
      });
    },
    formatMonto() {
      const numericValue = parseFloat(this.monto);
      if (!isNaN(numericValue)) {
        this.monto = numericValue;
      }
    },
    // Formato de moneda
    formatCurrency(value) {
      if (value === null || value === undefined || value === '') return '';
      return this.formatter.format(parseFloat(value));
    },

    // Manejar la entrada de Anticipo
    handleMonto(value) {
      this.montoFormatted = value; // Llamar al setter de anticipoFormatted
    },

    calcularTotales() {
      (this.totalNeto = 0),
        (this.totalIva = 0),
        (this.totalIibb = 0),
        (this.totalGeneral = 0),
        (this.otrosGastos = 0);
      this.ivaOtrosGastos = 0;
      this.iibbOtrosGastos = 0;
      this.gastos.forEach((g) => {
        this.honorariosMfs =
          g.descripcion === 'Gastos Administrativos'
            ? this.honorariosMfs + parseFloat(g.administrativos.administrativo)
            : this.honorariosMfs;

        this.honorariosMfsIva =
          g.descripcion === 'Gastos Administrativos'
            ? this.honorariosMfsIva + parseFloat(g.administrativos.iva)
            : this.honorariosMfsIva;

        this.totalHonorariosMfs =
          this.honorariosMfs + this.honorariosMfsIva + this.iibbHonorariosMfs;
        //--------
        this.otrosGastos =
          g.descripcion === 'Otorgamiento'
            ? this.otrosGastos + parseFloat(g.administrativos.otorgamiento)
            : this.otrosGastos;

        this.ivaOtrosGastos =
          g.descripcion === 'Otorgamiento'
            ? this.ivaOtrosGastos + parseFloat(g.administrativos.iva)
            : this.ivaOtrosGastos;

        this.iibbOtrosGastos =
          g.descripcion === 'Otorgamiento'
            ? this.iibbOtrosGastos + parseFloat(g.iibb)
            : this.iibbOtrosGastos;

        this.totalOtrosGastos = this.otrosGastos + this.ivaOtrosGastos;

        this.totalGeneral =
          this.totalHonorariosMfs +
          this.totalOtrosGastos +
          this.iibbOtrosGastos;

        this.totalIibb = this.iibbHonorariosMfs + this.iibbOtrosGastos;
        this.totalIva = this.honorariosMfsIva + this.ivaOtrosGastos;

        this.totalNeto = this.otrosGastos + this.iibbOtrosGastos;
      });
    },
    async cerrarModal() {
      this.dialog = false;
    },
    calcularIVA() {
      this.iva = parseFloat(this.monto * 0.21).toFixed(2);
    },
    async guardarGasto() {
      this.loading = true;
      //const url = `${API_URL}api/gastos/generar`;
      const url = `${API_URL}api/v1/gastos`;
      if (this.tipo_gasto === '') {
        return;
      }
      const data = {
        lineacreditocliente_id: this.lineacreditocliente_id,
        otorgamiento: '0',
        posteriores: '0',
        iva: '0',
        iibb: '0',
        desembolso_id: this.idDesembolso ? this.idDesembolso : '0',
      };
      data[this.tipo_gasto] = parseFloat(this.monto).toFixed(2).toString();

      if (this.iva_flag) {
        data.iva = parseFloat(this.iva).toFixed(2).toString();
      }

      await axios
        .post(url, data, this.header)
        .then((result) => {
          this.loading = false;
          this.$swal({
            title: 'Gasto nuevo',
            text: 'El gasto se generó con exito',
            type: 'success',
            timer: 2000, // Set timer to 2 seconds (2000 milliseconds)
          });

          this.getGastos();
          this.monto = '0';
          this.iva = '0';
          this.iva_flag = false;
          this.$emit('creditoLiquidado');
        })
        .catch((error) => {
          this.loading = false;
          console.error(error);
          this.$swal({
            title: 'Gasto nuevo',
            text: 'Ocurrió un error al generar el gasto',
            type: 'error',
            timer: 2000,
          });
          // this.$router.go(0);
        });
    },
    async getGastos() {
      try {
        this.mostrarLoading = true;
        const desembolsoId = this.anr ? this.idDesembolso || '0' : '0';
        const legajo = this.lineacreditocliente_id.toString();
        const query = {
          method: 'get',
          // url: `${API_URL}api/gastos/cc`,
          url: `${API_URL}api/v1/gastos/legajo/${legajo}/desembolso/${desembolsoId}`,
          headers: {
            token: this.token,
          },
        };
        const {
          data: { status, response },
        } = await axios(query);

        if (status === 'success') {
          this.gastos = response;
          this.calcularTotales();
        }
        this.mostrarLoading = false;
        if (status === 'error') {
          console.log(`Error ${message} statusCode: ${statusCode}`);
        }
      } catch (error) {
        console.error(error);
        this.mostrarLoading = false;
      }
    },
  },
  watch: {
    reRender(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.calcularTotales();
      }
    },
  },
  created() {
    this.getGastos();
  },
};
</script>

<style></style>
